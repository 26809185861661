import { apiSlice } from './apiSlice';

const adminInfo = localStorage.getItem('adminInfo')
  ? JSON.parse(localStorage.getItem('adminInfo'))
  : null;

export const adminAuthSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    adminLogin: builder.mutation({
      query: (data) => ({
        url: `/api/login`,
        method: 'POST',
        body: data,
      }),
      keepUnusedDataFor: 5,
    }),
    allUsers: builder.query({
      query: () => ({
        url: `/api/registered-users`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    countData: builder.query({
      query: () => ({
        url: `/api/count-data`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    allTransactions: builder.query({
      query: () => ({
        url: `/api/all-transactions`,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    evolves: builder.query({
      query: () => ({
        url: `/api/evolve`,
        method: 'POST',
      }),
      keepUnusedDataFor: 5,
    }),
    updateEvolve: builder.mutation({
      query: (data) => ({
        url: `/api/evolve/update`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useAdminLoginMutation,
  useAllUsersQuery,
  useCountDataQuery,
  useAllTransactionsQuery,
  useEvolvesQuery,
  useUpdateEvolveMutation,
} = adminAuthSlice;
