import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import FormContainer from '../../components/FormContainer';
import {
  useGetAnswerByIdQuery,
  useUpdateCourseAnswerMutation,
} from '../../slices/coursesApiSlice';
import Loader from '../../components/Loader';
import { toast } from 'react-toastify';

const EditAnswerScreen = () => {
  const [answer, setAnswer] = useState('');
  let [correct_answerBool, setCorectAnswer] = useState(false);
  const { id: uid } = useParams();

  const location = useLocation();
  const courseId = location?.state?.courseId;

  const { data: answeById } = useGetAnswerByIdQuery({ uid });
  const [updateCourseAnswer, { isLoading }] = useUpdateCourseAnswerMutation();

  useEffect(() => {
    if (answeById?.data) {
      setAnswer(answeById?.data?.answer);
      setCorectAnswer(answeById?.data?.correct_answer === 1 ? true : false);
    }
  }, [answeById]);

  const submitHandler = async (e) => {
    e.preventDefault();
    let correct_answer;
    correct_answer = correct_answerBool === false ? 0 : 1;

    try {
      // Call API route
      const response = await updateCourseAnswer({
        uid,
        answer,
        correct_answer,
      }).unwrap();

      if (response?.status === 'success') {
        toast.success('Successfully Updated answer');
      }
    } catch (error) {
      // Show Erorr
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
      if (error?.originalStatus === 500) {
        toast.error(error?.error);
      }
    }
  };

  return (
    <main className='main-container'>
      <Link to={`/admin/category-courses/questions/${courseId}`}>
        <Button style={{ backgroundColor: '#d49e33', border: 'None' }}>
          {' '}
          Go back
        </Button>
      </Link>{' '}
      <FormContainer>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='title' className='my-3'>
            <Form.Label>Answer </Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter answer'
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='title' className='my-3'>
            <Form.Label>Correct Answer</Form.Label>
            <Form.Check
              type='checkbox'
              checked={correct_answerBool}
              value={correct_answerBool}
              onChange={() => setCorectAnswer(!correct_answerBool)}
            />
          </Form.Group>

          <Button
            type='submit'
            style={{ backgroundColor: '#d49e33', border: 'None' }}
            className='mt-2'
            disabled={isLoading}
          >
            Edit Answer
          </Button>

          {isLoading && <Loader />}
        </Form>
      </FormContainer>
    </main>
  );
};

export default EditAnswerScreen;
