import { apiSlice } from './apiSlice';

const adminInfo = localStorage.getItem('adminInfo')
  ? JSON.parse(localStorage.getItem('adminInfo'))
  : null;

export const coursesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    categoryCourses: builder.query({
      query: () => ({
        url: `/api/category-course/all`,
        method: 'POST',
      }),
      keepUnusedDataFor: 5,
    }),
    createCategoryCourses: builder.mutation({
      query: (data) => ({
        url: `/api/category-course/create`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    allCoursesByCategory: builder.query({
      query: (id) => ({
        url: `/api/category-course`,
        method: 'POST',
        body: id,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getCourse: builder.query({
      query: (slug) => ({
        url: `/api/course/get`,
        method: 'POST',
        body: slug,
      }),
      keepUnusedDataFor: 5,
    }),
    createCourse: builder.mutation({
      query: (data) => ({
        url: `/api/course/create`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    updateCourse: builder.mutation({
      query: (data) => ({
        url: `/api/course/update`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    removeCourse: builder.mutation({
      query: (id) => ({
        url: `/api/course/delete/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    publishDraft: builder.mutation({
      query: (data) => ({
        url: ` /api/course/publish-draft`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    createCourseModule: builder.mutation({
      query: (data) => ({
        url: `/api/course/create/module`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    updateCourseModule: builder.mutation({
      query: (data) => ({
        url: `/api/course/update/module`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    removeModule: builder.mutation({
      query: (id) => ({
        url: `/api/course/delete/module/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getCourseQuestionAnswer: builder.query({
      query: (uid) => ({
        url: `/api/course-question-answer/get`,
        method: 'POST',
        body: uid,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    createCourseQuestion: builder.mutation({
      query: (data) => ({
        url: `/api/course-question/create`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    createCourseQuestionAnswer: builder.mutation({
      query: (data) => ({
        url: `/api/course-answer/create`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    removeAnswer: builder.mutation({
      query: (uid) => ({
        url: `/api/course-answer/delete/${uid}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    getAnswerById: builder.query({
      query: (uid) => ({
        url: `/api/course-answer/get`,
        method: 'POST',
        body: uid,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    updateCourseAnswer: builder.mutation({
      query: (data) => ({
        url: `/api/course-answer/update`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    removeCourseQuestion: builder.mutation({
      query: (uid) => ({
        url: `/api/course-question/delete/${uid}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    allReviews: builder.query({
      query: ({ course_id }) => ({
        url: `/api/course-rating/all`,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
        method: 'POST',
        body: { course_id },
      }),
      keepUnusedDataFor: 5,
    }),
    approveReview: builder.mutation({
      query: (data) => ({
        url: ` /api/course-rating/publish-draft`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useCategoryCoursesQuery,
  useCreateCategoryCoursesMutation,
  useAllCoursesByCategoryQuery,
  useGetCourseQuery,
  useCreateCourseMutation,
  useCreateCourseModuleMutation,
  useUpdateCourseMutation,
  useRemoveCourseMutation,
  usePublishDraftMutation,
  useUpdateCourseModuleMutation,
  useRemoveModuleMutation,
  useGetCourseQuestionAnswerQuery,
  useCreateCourseQuestionMutation,
  useCreateCourseQuestionAnswerMutation,
  useRemoveAnswerMutation,
  useRemoveCourseQuestionMutation,
  useUpdateCourseAnswerMutation,
  useGetAnswerByIdQuery,
  useAllReviewsQuery,
  useApproveReviewMutation,
} = coursesApiSlice;
