import {
  BsFillArchiveFill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
  BsFillBellFill,
} from 'react-icons/bs';
import { useCountDataQuery } from './slices/usersApiSlice';
import Message from './components/Message';
import Loader from './components/Loader';
import { useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useSelector } from 'react-redux';

const Home = () => {
  // Call API
  const { data: countData, isLoading, error } = useCountDataQuery();
  const { adminInfo } = useSelector((state) => state.adminInfo);

  const token = jwtDecode(adminInfo?.data);

  useEffect(() => {
    let timeNow = new Date();
    let epochTimeToken = token?.exp * 1000;
    let tokenDate = new Date(epochTimeToken);

    let tokenDateCheck = new Date(tokenDate);
    let timeNowCheck = new Date(timeNow);
    if (timeNowCheck > tokenDateCheck) {
      localStorage.removeItem('admininfo');
      localStorage.clear();
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    }
  }, [token]);

  return (
    <main className='main-container'>
      <div className='main-title'>
        <h3>DASHBOARD</h3>
      </div>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.error}</Message>
      ) : (
        <div className='main-cards'>
          <div className='card'>
            <div className='card-inner'>
              <h3>Course category</h3>
              <BsFillArchiveFill className='card_icon' />
            </div>
            <h1>{countData?.data?.category_course}</h1>
          </div>
          <div className='card'>
            <div className='card-inner'>
              <h3>Courses</h3>
              <BsFillGrid3X3GapFill className='card_icon' />
            </div>
            <h1>{countData?.data?.courses}</h1>
          </div>
          <div className='card'>
            <div className='card-inner'>
              <h3>Registered Users</h3>
              <BsPeopleFill className='card_icon' />
            </div>
            <h1>{countData?.data?.registered_users}</h1>
          </div>
          <div className='card'>
            <div className='card-inner'>
              <h3>PDF</h3>
              <BsFillBellFill className='card_icon' />
            </div>
            <h1>{countData?.data?.pdf}</h1>
          </div>
          <div className='card' style={{ color: 'black' }}>
            <div className='card-inner'>
              <h3>Test</h3>
              <BsFillBellFill className='card_icon' />
            </div>
            <h1>{countData?.data?.test}</h1>
          </div>
        </div>
      )}
    </main>
  );
};

export default Home;
