import React from 'react';
import { useCategoriesQuery } from '../../slices/motivationApiSlice';
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader';
import Message from '../../components/Message';

const MotivationScreen = () => {
  const { data: categories, isLoading, error } = useCategoriesQuery();

  return (
    <main className='main-container'>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.error}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Category Name</th>
              <th>Posts</th>
            </tr>
          </thead>
          <tbody>
            {categories?.data &&
              categories?.data.map((category, index) => (
                <tr key={category.id}>
                  <td>{index + 1}</td>
                  <td>{category.name}</td>
                  <td style={{ textAlign: 'center' }}>
                    <Link
                      to={`/admin/motivation/category/${category.id}/${category.slug}`}
                    >
                      <Button>All Posts</Button>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </main>
  );
};

export default MotivationScreen;
