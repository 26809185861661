import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { Link, useLocation, useParams } from 'react-router-dom';
import FormContainer from '../../components/FormContainer';
import {
  useCategoryCoursesQuery,
  useGetCourseQuery,
  useUpdateCourseMutation,
} from '../../slices/coursesApiSlice';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import { convertTo64 } from '../../helpers/convertTo64';

const EditCourseScreen = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState(0);
  const [duration, setDuration] = useState('');
  const [category_class_id, setCategoryClassId] = useState('');

  let [image_name, setImageName] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const [image, setImage] = useState(null);

  const { id: uid } = useParams();

  const location = useLocation();
  const course = location?.state?.course;
  const courseSlug = location?.state?.courseSlug;

  const slug = course?.slug;

  const { data: getCourse } = useGetCourseQuery({ slug });

  const { data: categoryCourses } = useCategoryCoursesQuery();

  const [updateCourse, { isUpdateCourseLoading }] = useUpdateCourseMutation();

  useEffect(() => {
    if (getCourse?.data) {
      setName(getCourse?.data?.name);
      // setDescription(getCourse?.data?.description);
      setDescription(JSON.parse(getCourse?.data?.description));
      setAmount(getCourse?.data?.amount);
      setDuration(getCourse?.data?.duration);
      setCategoryClassId(getCourse?.data?.category_class_id);
      setImagePreview(getCourse?.data?.image);
    }
  }, [getCourse]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'],
      [{ color: [] }, { background: [] }],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
    'size',
    'font',
    'video',
  ];

  let imageName;
  const submitHandler = async (e) => {
    e.preventDefault();

    imageName = image_name.split('\\');
    imageName = imageName[imageName.length - 1].split('.');
    imageName = imageName[0];
    image_name = imageName;

    try {
      // Call API route
      const response = await updateCourse({
        uid,
        name,
        description,
        amount,
        duration,
        category_class_id,
        image_name,
        image,
      }).unwrap();
      if (response?.status === 'success') {
        toast.success('Successfully Updated a Post');
      }
    } catch (error) {
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
      if (error?.originalStatus === 500) {
        toast.error(error?.error);
      }
    }
  };

  return (
    <main className='main-container'>
      <Link
        to={`/admin/category-courses/${course?.category_class_id}/${courseSlug}`}
      >
        <Button style={{ backgroundColor: '#d49e33', border: 'None' }}>
          {' '}
          Go back
        </Button>
      </Link>
      <br />
      <br />
      <FormContainer>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='name' className='my-3'>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='description' className='my-3'>
            <Form.Label>Description</Form.Label>

            <ReactQuill
              modules={modules}
              formats={formats}
              theme='snow'
              value={description}
              onChange={setDescription}
            />

            {/* <Form.Control
              as='textarea'
              rows={3}
              type='description'
              placeholder='Enter description'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></Form.Control> */}
          </Form.Group>

          <Form.Group controlId='amount' className='my-3'>
            <Form.Label>Amount</Form.Label>
            <Form.Control
              type='number'
              placeholder='Enter amount'
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='duration' className='my-3'>
            <Form.Label>Duration</Form.Label>
            <Form.Control
              type='number'
              placeholder='Enter duration'
              value={duration}
              onChange={(e) => setDuration(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <br />
          <Form.Group controlId='category_class_id'>
            <Form.Label>Select Category for Course</Form.Label>
            <Form.Select
              name='category_class_id'
              value={category_class_id}
              onChange={(e) => {
                setCategoryClassId(e.target.value);
              }}
            >
              {categoryCourses?.data &&
                categoryCourses?.data.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>

          <Form.Group controlId='image'>
            <Form.Label>Image</Form.Label>
            <Form.Control
              type='file'
              value={image_name}
              onChange={async (e) => {
                let imageName = e.target.value;
                setImageName(imageName);
                const base = await convertTo64(e.target.files[0]);
                setImage(base);
              }}
            />
          </Form.Group>
          {imagePreview && (
            <img
              style={{ width: '400px' }}
              src={`/api/show-file/${imagePreview}`}
              alt='Card'
            />
          )}
          <br />

          <Button
            type='submit'
            style={{ backgroundColor: '#d49e33', border: 'None' }}
            className='mt-2'
            disabled={isUpdateCourseLoading}
          >
            Update
          </Button>

          {isUpdateCourseLoading && <Loader />}
        </Form>
      </FormContainer>
    </main>
  );
};

export default EditCourseScreen;
