import React, { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Link, useLocation } from 'react-router-dom';
import 'react-quill/dist/quill.snow.css';
import Loader from '../../components/Loader';
import {
  useGetCourseQuery,
  useRemoveModuleMutation,
} from '../../slices/coursesApiSlice';
import Message from '../../components/Message';
import CreateModulesForm from './CreateModulesForm';
import ConfirmModal from '../../components/ConfirmModal';

const CreateCourseModulesScreen = () => {
  const [openForm, setOpenForm] = useState(false);
  const location = useLocation();
  const course = location?.state?.course;
  const courseSlug = location?.state?.courseSlug;

  // Create api call
  const slug = course?.slug;

  const {
    data: getCourse,
    isCourseLoading,
    courseError,
    refetch,
  } = useGetCourseQuery({ slug });

  const [deleteModule] = useRemoveModuleMutation();

  return (
    <main className='main-container'>
      <Link
        to={`/admin/category-courses/${course?.category_class_id}/${courseSlug}`}
      >
        <Button style={{ backgroundColor: '#d49e33', border: 'None' }}>
          {' '}
          Go back
        </Button>
      </Link>{' '}
      <Button
        style={{ backgroundColor: '#d49e33', border: 'None' }}
        onClick={() => {
          setOpenForm(!openForm);
        }}
      >
        Add new Module
      </Button>
      {openForm && <CreateModulesForm />}
      <br />
      <br />
      {isCourseLoading ? (
        <Loader />
      ) : courseError ? (
        <Message variant='danger'>{courseError?.error}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Edit</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {getCourse?.data?.course_module &&
              getCourse?.data?.course_module.map((module, index) => (
                <tr key={module.id}>
                  <td>{index + 1}</td>
                  <td>{module.module_title}</td>
                  <td style={{ textAlign: 'center' }}>
                    <Link
                      to={`/admin/courses/modules/${module.id}`}
                      state={{ module, course }}
                    >
                      <Button>Edit Module</Button>
                    </Link>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <ConfirmModal
                      id={module?.id}
                      refetch={refetch}
                      deleteFunc={deleteModule}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </main>
  );
};

export default CreateCourseModulesScreen;
