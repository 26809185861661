import React, { useState } from 'react';
import FormContainer from '../../components/FormContainer';
import { Button, Form } from 'react-bootstrap';
import { useCreateCourseQuestionAnswerMutation } from '../../slices/coursesApiSlice';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';

const AddQuestionAnswerScreen = () => {
  const [answer, setAnswer] = useState('');
  let [correct_answerBool, setCorectAnswer] = useState(false);
  const { id: question_id } = useParams();

  const navigate = useNavigate();

  // Create api call
  const [createQuestionAnswer, { isLoading }] =
    useCreateCourseQuestionAnswerMutation();

  const submitHandler = async (e) => {
    e.preventDefault();
    let correct_answer;
    correct_answer = correct_answerBool === false ? 0 : 1;

    try {
      // Call API route
      const response = await createQuestionAnswer({
        question_id,
        answer,
        correct_answer,
      }).unwrap();
      if (response?.status === 'success') {
        toast.success('Successfully Added a new Answer');
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (error) {
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 400) {
        toast.error(error?.data?.data);
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
    }
  };
  return (
    <main className='main-container'>
      <Link>
        <Button
          onClick={() => navigate(-1)}
          style={{ backgroundColor: '#d49e33', border: 'None' }}
        >
          {' '}
          Go back
        </Button>
      </Link>{' '}
      <FormContainer>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='title' className='my-3'>
            <Form.Label>Answer </Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter answer'
              value={answer}
              onChange={(e) => setAnswer(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='title' className='my-3'>
            <Form.Label>Correct Answer</Form.Label>
            <Form.Check
              type='checkbox'
              value={correct_answerBool}
              onChange={() => setCorectAnswer(!correct_answerBool)}
            />
          </Form.Group>

          <Button
            type='submit'
            style={{ backgroundColor: '#d49e33', border: 'None' }}
            className='mt-2'
            disabled={isLoading}
          >
            Add Answer
          </Button>

          {isLoading && <Loader />}
        </Form>
      </FormContainer>
    </main>
  );
};

export default AddQuestionAnswerScreen;
