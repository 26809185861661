import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import FormContainer from '../components/FormContainer';
import { setCredentials } from '../slices/authSlice';
import { Button, Form } from 'react-bootstrap';
import { useAdminLoginMutation } from '../slices/usersApiSlice';

const LoginScreen = () => {
  // Declare state variables
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // Decleare side function
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Create api call
  const [adminLogin, { isLoading }] = useAdminLoginMutation();

  // use adminInfo state
  const { adminInfo } = useSelector((state) => state?.adminInfo);

  // UseEffect if we already loged in
  useEffect(() => {
    if (adminInfo) {
      navigate('/');
    }
  }, [adminInfo, navigate]);

  // Create submit handler
  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      // Call API route
      const response = await adminLogin({ email, password }).unwrap();
      dispatch(setCredentials({ ...response }));
      navigate('/');
      window.location.reload(false);
    } catch (error) {
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
    }
  };

  return (
    <main className='main-container'>
      <FormContainer>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='email' className='my-3'>
            <Form.Label>Email Address</Form.Label>
            <Form.Control
              type='email'
              placeholder='Enter email'
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='password' className='my-3'>
            <Form.Label>Password</Form.Label>
            <Form.Control
              type='password'
              placeholder='Enter password'
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Button
            type='submit'
            style={{
              backgroundColor: '#d49e33',
              border: 'None',
              width: '100%',
            }}
            className='mt-2'
            disabled={isLoading}
          >
            Sign In
          </Button>
        </Form>
      </FormContainer>
    </main>
  );
};

export default LoginScreen;
