import React, { useState } from 'react';
import { usePdfsQuery, useRemovePdfMutation } from '../../slices/freeApiSlice';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import CreatePdfForm from './CreatePdfForm';
import ConfirmModal from '../../components/ConfirmModal';

const PdfScreen = () => {
  const [openForm, setOpenForm] = useState(false);
  const appUrl = process.env.REACT_APP_URL;

  // API Calls
  const { data: pdfs, isLoading, error, refetch } = usePdfsQuery();

  const [deletePdf, { isRemoveLoading }] = useRemovePdfMutation();

  return (
    <main className='main-container'>
      <Link to='/admin/free'>
        <Button style={{ backgroundColor: '#d49e33', border: 'None' }}>
          {' '}
          Go back
        </Button>
      </Link>{' '}
      <Button
        style={{ backgroundColor: '#d49e33', border: 'None' }}
        onClick={() => {
          setOpenForm(!openForm);
        }}
      >
        Add new Pdf
      </Button>
      {openForm && <CreatePdfForm />}
      <br />
      <br />
      {isLoading || isRemoveLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.error}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Category Name</th>
              <th>Remove</th>
              <th>Download</th>
            </tr>
          </thead>
          <tbody>
            {pdfs?.data &&
              pdfs?.data.map((pdf, index) => (
                <tr key={pdf.id}>
                  <td>{index + 1}</td>
                  <td>{pdf.title}</td>
                  <td style={{ textAlign: 'center' }}>
                    <ConfirmModal
                      id={pdf.id}
                      refetch={refetch}
                      deleteFunc={deletePdf}
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <a
                      style={{
                        textDecoration: 'None',
                      }}
                      href={`${appUrl}/api/pdf/public-downoload/${pdf.file_name}`}
                      download
                    >
                      <Button>Download</Button>
                    </a>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </main>
  );
};

export default PdfScreen;
