import React, { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useTestsQuery } from '../../slices/freeApiSlice';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import CreateTestForm from './CreateTestForm';

const TestScreen = () => {
  const [openForm, setOpenForm] = useState(false);

  // API Calls
  const { data: tests, isLoading, error } = useTestsQuery();

  return (
    <main className='main-container'>
      <Link to='/admin/free'>
        <Button style={{ backgroundColor: '#d49e33', border: 'None' }}>
          {' '}
          Go back
        </Button>
      </Link>{' '}
      <Button
        style={{ backgroundColor: '#d49e33', border: 'None' }}
        onClick={() => {
          setOpenForm(!openForm);
        }}
      >
        Add new test
      </Button>
      {openForm && <CreateTestForm />}
      <br />
      <br />
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.error}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Test Name</th>
              <th>Edit</th>
              <th>Questions</th>
              <th>Result for Problem</th>
            </tr>
          </thead>
          <tbody>
            {tests?.data &&
              tests?.data.map((test, index) => (
                <tr key={test.id}>
                  <td>{index + 1}</td>
                  <td>{test.name}</td>
                  <td style={{ textAlign: 'center' }}>
                    <Link to={`/admin/tests/${test.id}`} state={{ test }}>
                      <Button>Edit Test</Button>
                    </Link>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Link
                      to={`/admin/free/tests/questions/${test.id}`}
                      state={{ test }}
                    >
                      <Button>Questions</Button>
                    </Link>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Link
                      to={`/admin/free/tests/problems/${test.id}`}
                      state={{ test }}
                    >
                      <Button>Problems</Button>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </main>
  );
};

export default TestScreen;
