import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import FormContainer from '../../components/FormContainer';
import { toast } from 'react-toastify';
import { convertTo64 } from '../../helpers/convertTo64';
import {
  useCategoriesQuery,
  useSingleBlogQuery,
  useUdpateBlogMutation,
  useUpoadImagebase64Mutation,
} from '../../slices/motivationApiSlice';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Loader from '../../components/Loader';
import { changeSrc } from '../../helpers/changeSrc';
import { Editor } from '@tinymce/tinymce-react';

const EditPostScreen = () => {
  const [name, setName] = useState('');
  let [description, setDescription] = useState('');
  let [image_name, setImageName] = useState('');
  const [image, setImage] = useState(null);
  const [category_id, setCategoryId] = useState('');
  const [imagePreview, setImagePreview] = useState('');

  let { id: uid, slug } = useParams();
  const location = useLocation();
  const REACT_APP_TINY_EDITOR = process.env.REACT_APP_TINY_EDITOR;

  const categoySlug = location?.state?.categoySlug;
  // console.log('blog ', blog);

  const { data: categories } = useCategoriesQuery();
  const { data: blog } = useSingleBlogQuery({ slug });

  useEffect(() => {
    if (blog?.data) {
      setName(blog?.data?.name);
      setDescription(JSON.parse(blog?.data?.description));
      setCategoryId(blog?.data?.category_id);
      setImagePreview(blog?.data?.image);
    }
  }, [blog]);

  // Quill
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'],
      [{ color: [] }, { background: [] }],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
    'size',
    'font',
    'video',
  ];

  // Editor
  const editorRef = useRef(null);
  const handleEditorChange = (newContent, editor) => {
    setDescription(newContent);
  };

  // Create api call
  const [updateBlog, { isBlogUpdateLoading }] = useUdpateBlogMutation();
  const [uploadBase64] = useUpoadImagebase64Mutation();

  let imageName;
  const submitHandler = async (e) => {
    e.preventDefault();

    imageName = image_name.split('\\');
    imageName = imageName[imageName.length - 1].split('.');
    imageName = imageName[0];
    image_name = imageName;

    description = await changeSrc(description, uploadBase64);

    try {
      // Call API route
      const response = await updateBlog({
        uid,
        name,
        description,
        category_id,
        image_name,
        image,
      }).unwrap();

      if (response?.status === 'success') {
        toast.success('Successfully Updated a Post');
      }
    } catch (error) {
      // Show Erorr
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
      if (error?.originalStatus === 500) {
        toast.error(error?.error);
      }
    }
  };

  return (
    <main className='main-container'>
      <Link
        to={`/admin/motivation/category/${blog?.data?.category_id}/${categoySlug}`}
      >
        <Button style={{ backgroundColor: '#d49e33', border: 'None' }}>
          {' '}
          Go back
        </Button>
      </Link>{' '}
      <FormContainer>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='name' className='my-3'>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type='name'
              placeholder='Enter title'
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='description' className='my-3'>
            <Form.Label>Description</Form.Label>
            {/* <Form.Control
              as='textarea'
              rows={3}
              type='description'
              placeholder='Enter description'
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></Form.Control> */}

            <ReactQuill
              theme='snow'
              modules={modules}
              formats={formats}
              value={description}
              onChange={setDescription}
            />

            {/* <Editor
              apiKey={REACT_APP_TINY_EDITOR}
              onInit={(evt, editor) => (editorRef.current = editor)}
              onEditorChange={handleEditorChange}
              initialValue={description}
              init={{
                height: 500,
                menubar: false,
                plugins: [
                  'advlist',
                  'autolink',
                  'lists',
                  'link',
                  'image',
                  'charmap',
                  'preview',
                  'anchor',
                  'searchreplace',
                  'visualblocks',
                  'code',
                  'fullscreen',
                  'insertdatetime',
                  'media',
                  'table',
                  'code',
                  'help',
                  'wordcount',
                  'mentions',
                  'codesample',
                  'emoticons',
                  'mediaembed',
                  'casechange',
                  'export',
                  'formatpainter',
                  'pageembed',
                  'permanentpen',
                  'footnotes',
                  'advtemplate',
                  'advtable',
                  'advcode',
                  'editimage',
                  'tableofcontents',
                  'mergetags',
                  'powerpaste',
                  'tinymcespellchecker',
                  'autocorrect',
                  'a11ychecker',
                  'typography',
                  'inlinecss',
                ],
                toolbar:
                  'undo redo | blocks | ' +
                  'bold italic forecolor | alignleft aligncenter ' +
                  'alignright alignjustify | bullist numlist outdent indent | ' +
                  'removeformat | help' +
                  'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap',
                content_style:
                  'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
              }}
            /> */}
          </Form.Group>

          <Form.Group controlId='image'>
            <Form.Label>Card Image</Form.Label>
            <Form.Control
              type='file'
              value={image_name}
              onChange={async (e) => {
                let imageName = e.target.value;
                setImageName(imageName);
                const base = await convertTo64(e.target.files[0]);
                setImage(base);
              }}
            />
          </Form.Group>
          <br />
          {imagePreview && (
            <img src={`/api/show-file/${imagePreview}`} alt='Card' />
          )}
          <br />
          <Form.Group controlId='category_id'>
            <Form.Label>Select Category for Blog</Form.Label>
            <Form.Select
              name='category_id'
              value={category_id}
              onChange={(e) => {
                setCategoryId(e.target.value);
              }}
            >
              {categories?.data &&
                categories?.data.map((category) => (
                  <option key={category.id} value={category.id}>
                    {category.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>

          <Button
            type='submit'
            style={{ backgroundColor: '#d49e33', border: 'None' }}
            className='mt-2'
            disabled={isBlogUpdateLoading}
          >
            Update
          </Button>

          {isBlogUpdateLoading && <Loader />}
        </Form>
      </FormContainer>
    </main>
  );
};

export default EditPostScreen;
