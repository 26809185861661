import React from 'react';
import { BsFillGrid3X3GapFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const FreeScreen = () => {
  return (
    <main className='main-container'>
      <div className='main-cards'>
        <div className='card'>
          <Link
            to='/admin/pdfs'
            style={{ textDecoration: 'None', color: 'white' }}
          >
            <div className='card-inner'>
              <h3>DOWNLOADS</h3>

              <BsFillGrid3X3GapFill className='card_icon' />
            </div>
          </Link>
        </div>

        <div className='card'>
          <Link
            to='/admin/tests'
            style={{ textDecoration: 'None', color: 'white' }}
          >
            <div className='card-inner'>
              <h3>TESTS</h3>
              <BsFillGrid3X3GapFill className='card_icon' />
            </div>
          </Link>
        </div>
      </div>
    </main>
  );
};

export default FreeScreen;
