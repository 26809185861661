import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import { useUpdateCourseModuleMutation } from '../../slices/coursesApiSlice';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { changeSrc } from '../../helpers/changeSrc';
import { useUpoadImagebase64Mutation } from '../../slices/motivationApiSlice';
import { Editor } from '@tinymce/tinymce-react';

const EditModuleScreen = () => {
  const [module_title, setModuleTitle] = useState('');
  let [module_content, setModuleContent] = useState('');
  const { id: uid } = useParams();

  const [updateCourse, { isLoading }] = useUpdateCourseModuleMutation();
  const [uploadBase64] = useUpoadImagebase64Mutation();

  const navigate = useNavigate();
  const location = useLocation();
  const module = location?.state?.module;
  const course = location?.state?.course;

  const REACT_APP_TINY_EDITOR = process.env.REACT_APP_TINY_EDITOR;

  useEffect(() => {
    if (module) {
      setModuleTitle(module?.module_title);
      setModuleContent(JSON.parse(module?.module_content));
    }
  }, [module]);

  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'],
      [{ color: [] }, { background: [] }],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
    'size',
    'font',
    'video',
  ];

  const editorRef = useRef(null);
  const handleEditorChange = (newContent, editor) => {
    setModuleContent(newContent);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    module_content = await changeSrc(module_content, uploadBase64);
    try {
      // Call API route
      const response = await updateCourse({
        uid,
        module_title,
        module_content,
      }).unwrap();

      toast.success('Successfully updated module');

      if (response?.status === 'success') {
        toast.success('Successfully updated module');
      }
    } catch (error) {
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
      if (error?.originalStatus === 500) {
        toast.error(error?.error);
      }
    }
  };

  return (
    <main className='main-container'>
      <Link>
        <Button
          onClick={() => navigate(-1)}
          style={{ backgroundColor: '#d49e33', border: 'None' }}
        >
          {' '}
          Go back
        </Button>
      </Link>{' '}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='module_title' className='my-3'>
          <Form.Label>Module Title</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter title'
            value={module_title}
            onChange={(e) => setModuleTitle(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='module_title' className='my-3'>
          Module Description{' '}
          <ReactQuill
            modules={modules}
            formats={formats}
            theme='snow'
            value={module_content}
            onChange={setModuleContent}
          />
          {/* <Editor
            apiKey={REACT_APP_TINY_EDITOR}
            onInit={(evt, editor) => (editorRef.current = editor)}
            onEditorChange={handleEditorChange}
            initialValue={module_content}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist',
                'autolink',
                'lists',
                'link',
                'image',
                'charmap',
                'preview',
                'anchor',
                'searchreplace',
                'visualblocks',
                'code',
                'fullscreen',
                'insertdatetime',
                'media',
                'table',
                'code',
                'help',
                'wordcount',
                'mentions',
                'codesample',
                'emoticons',
                'mediaembed',
                'casechange',
                'export',
                'formatpainter',
                'pageembed',
                'permanentpen',
                'footnotes',
                'advtemplate',
                'advtable',
                'advcode',
                'editimage',
                'tableofcontents',
                'mergetags',
                'powerpaste',
                'tinymcespellchecker',
                'autocorrect',
                'a11ychecker',
                'typography',
                'inlinecss',
              ],
              toolbar:
                'undo redo | blocks | ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help' +
                'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap',
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          /> */}
        </Form.Group>

        <Button
          type='submit'
          style={{ backgroundColor: '#d49e33', border: 'None' }}
          className='mt-2'
          disabled={isLoading}
        >
          Update
        </Button>

        {isLoading && <Loader />}
      </Form>
    </main>
  );
};

export default EditModuleScreen;
