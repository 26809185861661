import React, { useEffect, useState } from 'react';
import FormContainer from '../../components/FormContainer';
import { convertTo64 } from '../../helpers/convertTo64';
import { Button, Form } from 'react-bootstrap';
import Loader from '../../components/Loader';
import { Link, useParams } from 'react-router-dom';
import {
  useSingleTestQuery,
  useUdpateTestMutation,
} from '../../slices/freeApiSlice';
import { toast } from 'react-toastify';

const EditTestScreen = () => {
  const [name, setName] = useState('');
  let [image_name, setImageName] = useState('');
  const [imagePreview, setImagePreview] = useState('');
  const [image, setImage] = useState(null);

  let { testId: uid } = useParams();
  const { data: test } = useSingleTestQuery({ test_id: uid });
  const [updateTest, { isTestUpdateLoading }] = useUdpateTestMutation();

  useEffect(() => {
    if (test?.data) {
      setName(test?.data?.name);
      setImagePreview(test?.data?.image);
    }
  }, [test]);

  let imageName;
  const submitHandler = async (e) => {
    e.preventDefault();

    imageName = image_name.split('\\');
    imageName = imageName[imageName.length - 1].split('.');
    imageName = imageName[0];
    image_name = imageName;

    try {
      // Call API route
      const response = await updateTest({
        uid,
        name,
        image_name,
        image,
      }).unwrap();

      if (response?.status === 'success') {
        toast.success('Successfully Updated a Test');
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (error) {
      // Show Erorr
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
      if (error?.originalStatus === 500) {
        toast.error(error?.error);
      }
    }
  };

  return (
    <main className='main-container'>
      <Link to={`/admin/tests`}>
        <Button style={{ backgroundColor: '#d49e33', border: 'None' }}>
          {' '}
          Go back
        </Button>
      </Link>{' '}
      <FormContainer>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='name' className='my-3'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='image'>
            <Form.Label>Image</Form.Label>
            <Form.Control
              type='file'
              value={image_name}
              onChange={async (e) => {
                let imageName = e.target.value;
                setImageName(imageName);
                const base = await convertTo64(e.target.files[0]);
                setImage(base);
              }}
            />
          </Form.Group>
          {imagePreview && (
            <img
              style={{ width: '400px' }}
              src={`/api/show-file/${imagePreview}`}
              alt='Card'
            />
          )}
          <br />

          <Button
            type='submit'
            style={{ backgroundColor: '#d49e33', border: 'None' }}
            className='mt-2'
            disabled={isTestUpdateLoading}
          >
            Update
          </Button>

          {isTestUpdateLoading && <Loader />}
        </Form>
      </FormContainer>
    </main>
  );
};

export default EditTestScreen;
