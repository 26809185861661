import React, { useState } from 'react';
import FormContainer from '../../components/FormContainer';
import { Button, Form } from 'react-bootstrap';
import Loader from '../../components/Loader';
import ReactQuill from 'react-quill';
import { useCreateProblemMutation } from '../../slices/freeApiSlice';
import { toast } from 'react-toastify';

const ProblemForm = ({ test_id }) => {
  const [title, setTitle] = useState('');
  let [description, setDescription] = useState('');
  const [percentage, setPercent] = useState(0);

  // Quill
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      [{ color: [] }, { background: [] }],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
    'size',
    'font',
  ];

  // Create api call
  const [createProblem, { isLoading }] = useCreateProblemMutation();

  const submitHandler = async (e) => {
    e.preventDefault();
    if (percentage === 0) {
      toast.error('Please select Percent');
    } else {
      try {
        // Call API route
        const response = await createProblem({
          test_id,
          title,
          description,
          percentage,
        }).unwrap();

        if (response?.status === 'success') {
          toast.success('Successfully Added new Problem');
        }
        setTimeout(() => {
          window.location.reload(false);
        }, 1000);
      } catch (error) {
        const errorArr = error?.data?.errors;
        if (errorArr) {
          let arr = Object.values(errorArr);
          arr.map((e) => toast.error(e[0]));
        }
        if (error?.status === 500) {
          toast.error(error?.data?.data);
        }
      }
    }
  };

  return (
    <FormContainer>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='name' className='my-3'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Name'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='description' className='my-3'>
          <Form.Label>Description</Form.Label>
          <ReactQuill
            theme='snow'
            modules={modules}
            formats={formats}
            value={description}
            onChange={setDescription}
          />
        </Form.Group>

        <Form.Group controlId='category_id'>
          <Form.Label>Select Percent </Form.Label>
          <Form.Select
            name='percent'
            value={percentage}
            onChange={(e) => {
              setPercent(e.target.value);
            }}
          >
            <option value={0}>Select Percent</option>
            <option value={33}>33 %</option>
            <option value={66}>66 %</option>
            <option value={100}>100 %</option>
          </Form.Select>
        </Form.Group>

        <Button
          type='submit'
          style={{ backgroundColor: '#d49e33', border: 'None' }}
          className='mt-2'
          disabled={isLoading}
        >
          Add Problem
        </Button>

        {isLoading && <Loader />}
      </Form>
    </FormContainer>
  );
};

export default ProblemForm;
