import React from 'react';
import { useAllUsersQuery } from '../../slices/usersApiSlice';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { Table } from 'react-bootstrap';

const UsersScreen = () => {
  const { data: allUsers, isLoading, error } = useAllUsersQuery();

  return (
    <main className='main-container'>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.error}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
            </tr>
          </thead>
          <tbody>
            {allUsers?.data &&
              allUsers?.data.map((user, index) => (
                <tr key={user.id}>
                  <td>{index + 1}</td>
                  <td>{user.first_name}</td>
                  <td>{user.last_name}</td>
                  <td>{user.email}</td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </main>
  );
};

export default UsersScreen;
