import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from './slices/authSlice';
import { Button } from 'react-bootstrap';

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { adminInfo } = useSelector((state) => state?.adminInfo);

  const logoutHandler = async () => {
    try {
      dispatch(logout());
      navigate('/admin/login');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <header className='header'>
      <div className='menu-icon'></div>
      <div className='header-left'></div>
      <div className='header-right'>
        {adminInfo && (
          <Button
            style={{ backgroundColor: '#d49e33', border: 'None' }}
            onClick={logoutHandler}
          >
            Logout
          </Button>
        )}
      </div>
    </header>
  );
};

export default Header;
