import React, { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  useRemoveQuestionMutation,
  useSingleTestQuery,
} from '../../slices/freeApiSlice';
import { Button, Table } from 'react-bootstrap';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import CreateQuestionForm from './CreateQuestionForm';
import ConfirmModal from '../../components/ConfirmModal';

const QuestionsScreen = () => {
  const [openForm, setOpenForm] = useState(false);

  const { id: test_id } = useParams();

  const {
    data: test,
    isLoading,
    error,
    refetch,
  } = useSingleTestQuery({ test_id });

  const [deleteQuestion] = useRemoveQuestionMutation();

  return (
    <main className='main-container'>
      <Link to='/admin/tests'>
        <Button style={{ backgroundColor: '#d49e33', border: 'None' }}>
          {' '}
          Go back
        </Button>
      </Link>{' '}
      <Button
        style={{ backgroundColor: '#d49e33', border: 'None' }}
        onClick={() => {
          setOpenForm(!openForm);
        }}
      >
        Add new Question
      </Button>
      {openForm && <CreateQuestionForm test_id={test_id} />}
      <br />
      <br />
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.error}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Question</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {test?.data?.questions &&
              test?.data?.questions.map((question, index) => (
                <tr key={question.id}>
                  <td>{index + 1}</td>
                  <td>{question.name}</td>
                  <td>
                    <ConfirmModal
                      id={question?.id}
                      refetch={refetch}
                      deleteFunc={deleteQuestion}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </main>
  );
};

export default QuestionsScreen;
