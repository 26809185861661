import React, { useState } from 'react';
import FormContainer from '../../components/FormContainer';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import { useCreateCategoryCoursesMutation } from '../../slices/coursesApiSlice';

const CreateCategoryCoursesForm = () => {
  const [name, setName] = useState('');

  // Create api call
  const [createCategoryCourses, { isLoading }] =
    useCreateCategoryCoursesMutation();

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      // Call API route
      const response = await createCategoryCourses({
        name,
      }).unwrap();
      if (response?.status === 'success') {
        toast.success('Successfully Added a new Category');
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (error) {
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
    }
  };
  return (
    <FormContainer>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='name' className='my-3'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button
          type='submit'
          style={{ backgroundColor: '#d49e33', border: 'None' }}
          className='mt-2'
          disabled={isLoading}
        >
          Add category
        </Button>

        {isLoading && <Loader />}
      </Form>
    </FormContainer>
  );
};

export default CreateCategoryCoursesForm;
