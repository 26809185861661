import React from 'react';
import { useEvolvesQuery } from '../../slices/usersApiSlice';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Evolve = () => {
  const { data: evolves, isLoading, error } = useEvolvesQuery();

  return (
    <main className='main-container'>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.error}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Edit</th>
            </tr>
          </thead>
          <tbody>
            {evolves?.data &&
              evolves?.data.map((evolve, index) => (
                <tr key={evolve.id}>
                  <td>{index + 1}</td>
                  <td>{evolve.name}</td>
                  <td style={{ textAlign: 'center' }}>
                    <Link
                      to={`/admin/evolve/${evolve.id}`}
                      //   state={{ course, courseSlug: slug }}
                    >
                      <Button>Edit</Button>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </main>
  );
};

export default Evolve;
