import React from 'react';
import { useQuestionAnswerQuery } from '../../slices/freeApiSlice';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { Table, Button, Col } from 'react-bootstrap';

const SingleTestQuestionAnswerScreen = () => {
  const { id: test_id } = useParams();

  // API Calls
  const {
    data: questionAnswer,
    isLoading,
    error,
  } = useQuestionAnswerQuery({ test_id });

  return (
    <main className='main-container'>
      <Link to='/admin/question-answer'>
        <Button style={{ backgroundColor: '#d49e33', border: 'None' }}>
          {' '}
          Go back
        </Button>
      </Link>
      <br />
      <br />
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.error}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Users</th>
              <th>Answers</th>
            </tr>
          </thead>
          <tbody>
            {questionAnswer?.data?.questions &&
              questionAnswer?.data?.questions.map((question, index) => (
                <tr key={question.id}>
                  <td>{index + 1}</td>
                  <td>{question.name}</td>
                  <td>
                    {question?.answers &&
                      question.answers.map((answer, index) => (
                        <Col key={index + 1}>
                          <span>
                            {answer?.users?.first_name}{' '}
                            {answer?.users?.last_name}
                          </span>
                        </Col>
                      ))}
                  </td>
                  <td>
                    {question?.answers &&
                      question.answers.map((answer) => (
                        <Col key={answer.id}>
                          <span>{answer?.response}</span>
                        </Col>
                      ))}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </main>
  );
};

export default SingleTestQuestionAnswerScreen;
