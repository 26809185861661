import React, { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import {
  useAllCoursesByCategoryQuery,
  usePublishDraftMutation,
  useRemoveCourseMutation,
} from '../../slices/coursesApiSlice';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import CreateCourseForm from './CreateCourseForm';
import ConfirmModal from '../../components/ConfirmModal';
import { toast } from 'react-toastify';

const AllCoursesByCategoryScreen = () => {
  const [openForm, setOpenForm] = useState(false);

  const { id, slug } = useParams();

  const frontUrl = process.env.REACT_APP_FRONT_URL;

  const {
    data: allCourses,
    isLoading,
    error,
    refetch,
  } = useAllCoursesByCategoryQuery({ slug });

  const [publishDraft] = usePublishDraftMutation();

  const publishDraftHandler = async (e, uid, statusCourse) => {
    e.preventDefault();
    try {
      let status = statusCourse === 0 ? 1 : 0;

      // Call API route
      const response = await publishDraft({
        uid,
        status,
      }).unwrap();
      if (response?.status === 'success') {
        toast.success(
          status === 1
            ? 'Successfully Published Course'
            : 'Successfully set Course to Draft Mode'
        );
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (error) {
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
    }
  };

  const [deleteCourse] = useRemoveCourseMutation();

  return (
    <main className='main-container'>
      <Link to='/admin/category-courses'>
        <Button style={{ backgroundColor: '#d49e33', border: 'None' }}>
          {' '}
          Go back
        </Button>
      </Link>{' '}
      <Button
        style={{ backgroundColor: '#d49e33', border: 'None' }}
        onClick={() => {
          setOpenForm(!openForm);
        }}
      >
        Add new Course
      </Button>
      {openForm && <CreateCourseForm slug={slug} />}
      <br />
      <br />
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.error}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Short Description</th>
              <th>Amount</th>
              <th>Duration</th>
              <th>Status</th>
              <th>Draft Preview</th>
              <th>Publish/Draft</th>
              <th>Add questions</th>
              <th>Add modules</th>
              <th>Edit</th>
              <th>Remove</th>
              <th>Reviews</th>
            </tr>
          </thead>
          <tbody>
            {allCourses?.data?.courses &&
              allCourses?.data?.courses.map((course, index) => (
                <tr key={course.id}>
                  <td>{index + 1}</td>
                  <td>{course.name}</td>
                  <td>{course.short_description}</td>
                  <td>{course.amount}</td>
                  <td>{course.duration}</td>
                  <td>{course.status === 1 ? 'Published' : 'Draft'}</td>
                  <td style={{ textAlign: 'center' }}>
                    <a href={`${frontUrl}/courses/${course.slug}`}>
                      <Button>Draft Preview</Button>
                    </a>
                  </td>
                  <td>
                    <Button
                      onClick={(e) => {
                        publishDraftHandler(e, course.id, course.status);
                      }}
                    >
                      Publish/Draft
                    </Button>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Link
                      to={`/admin/category-courses/questions/${course.id}`}
                      state={{ course, courseSlug: slug }}
                    >
                      <Button>Add questions</Button>
                    </Link>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Link
                      to={`/admin/category-courses/modules/${course.id}`}
                      state={{ course, courseSlug: slug }}
                    >
                      <Button>Add modules</Button>
                    </Link>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Link
                      to={`/admin/courses/${course.id}`}
                      state={{ course, courseSlug: slug }}
                    >
                      <Button>Edit Course</Button>
                    </Link>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <ConfirmModal
                      id={course?.id}
                      refetch={refetch}
                      deleteFunc={deleteCourse}
                    />
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <Link
                      to={`/admin/courses/review/${course.id}`}
                      state={{ course, courseSlug: slug }}
                    >
                      <Button>Reviews</Button>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </main>
  );
};

export default AllCoursesByCategoryScreen;
