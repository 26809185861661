import React, { useEffect, useState } from 'react';
import FormContainer from '../../components/FormContainer';
import { Button, Form } from 'react-bootstrap';
import Loader from '../../components/Loader';
import ReactQuill from 'react-quill';
import { Link, useParams } from 'react-router-dom';
import {
  useProblemQuery,
  useUdpateProblemMutation,
} from '../../slices/freeApiSlice';
import { toast } from 'react-toastify';

const EditProblemScreen = () => {
  const [title, setTitle] = useState('');
  let [description, setDescription] = useState('');
  const [percentage, setPercent] = useState(0);

  let { testId: test_id, problemId: problem_id } = useParams();
  console.log('test_id ', test_id, 'problem_id ', problem_id);

  const { data: problem } = useProblemQuery({ uid: problem_id });
  const [updateProblem, { isProblemUpdateLoading }] =
    useUdpateProblemMutation();

  useEffect(() => {
    if (problem?.data) {
      setTitle(problem?.data?.title);
      setDescription(JSON.parse(problem?.data?.description));
      setPercent(problem?.data?.percentage);
    }
  }, [problem]);

  console.log('problem ', problem);

  // Quill
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
      [{ color: [] }, { background: [] }],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
    'size',
    'font',
  ];

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      // Call API route
      const response = await updateProblem({
        uid: problem_id,
        title,
        description,
        percentage,
      }).unwrap();

      if (response?.status === 'success') {
        toast.success('Successfully Updated a Problem');
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (error) {
      // Show Erorr
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
      if (error?.originalStatus === 500) {
        toast.error(error?.error);
      }
    }
  };

  return (
    <main className='main-container'>
      <Link to={`/admin/free/tests/problems/${test_id}`}>
        <Button style={{ backgroundColor: '#d49e33', border: 'None' }}>
          {' '}
          Go back
        </Button>
      </Link>{' '}
      <FormContainer>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='name' className='my-3'>
            <Form.Label>Name</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter Name'
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId='description' className='my-3'>
            <Form.Label>Description</Form.Label>
            <ReactQuill
              theme='snow'
              modules={modules}
              formats={formats}
              value={description}
              onChange={setDescription}
            />
          </Form.Group>

          <Form.Group controlId='category_id'>
            <Form.Label>Select Percent </Form.Label>
            <Form.Select
              name='percent'
              value={percentage}
              onChange={(e) => {
                setPercent(e.target.value);
              }}
            >
              <option value={33}>33 %</option>
              <option value={66}>66 %</option>
              <option value={100}>100 %</option>
            </Form.Select>
          </Form.Group>

          <Button
            type='submit'
            style={{ backgroundColor: '#d49e33', border: 'None' }}
            className='mt-2'
            disabled={isProblemUpdateLoading}
          >
            Update
          </Button>

          {isProblemUpdateLoading && <Loader />}
        </Form>
      </FormContainer>
    </main>
  );
};

export default EditProblemScreen;
