import React, { useRef, useState } from 'react';
import FormContainer from '../../components/FormContainer';
import { Button, Form } from 'react-bootstrap';
import {
  useCreateBlogMutation,
  useUpoadImagebase64Mutation,
} from '../../slices/motivationApiSlice';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { v4 as uuidv4 } from 'uuid';
import { convertTo64 } from '../../helpers/convertTo64';
import { changeSrc } from '../../helpers/changeSrc';

import { Editor } from '@tinymce/tinymce-react';

const CreatePostForm = ({ category_id }) => {
  const [name, setName] = useState('');
  let [description, setDescription] = useState('');
  let [image_name, setImageName] = useState('');
  const [image, setImage] = useState(null);

  const appUrl = process.env.REACT_APP_URL;
  const REACT_APP_TINY_EDITOR = process.env.REACT_APP_TINY_EDITOR;

  // Quill
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'],
      [{ color: [] }, { background: [] }],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
    'size',
    'font',
    'video',
  ];

  // Create api call
  const [blog, { isLoading }] = useCreateBlogMutation();

  const [uploadBase64] = useUpoadImagebase64Mutation();

  let imageName;

  const editorRef = useRef(null);
  const handleEditorChange = (newContent, editor) => {
    setDescription(newContent);
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    imageName = image_name.split('\\');
    imageName = imageName[imageName.length - 1].split('.');
    imageName = imageName[0];
    image_name = imageName;

    description = await changeSrc(description, uploadBase64);
    // log();
    try {
      // Call API route
      const response = await blog({
        name,
        description,
        category_id,
        image_name,
        image,
      }).unwrap();

      if (response?.status === 'success') {
        toast.success('Successfully Added new Post');
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (error) {
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
    }
  };

  return (
    <FormContainer>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='name' className='my-3'>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type='name'
            placeholder='Enter title'
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='description' className='my-3'>
          <Form.Label>Description</Form.Label>

          {/* <Editor
            apiKey={REACT_APP_TINY_EDITOR}
            onInit={(evt, editor) => (editorRef.current = editor)}
            onEditorChange={handleEditorChange}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist',
                'autolink',
                'lists',
                'link',
                'image',
                'charmap',
                'preview',
                'anchor',
                'searchreplace',
                'visualblocks',
                'code',
                'fullscreen',
                'insertdatetime',
                'media',
                'table',
                'code',
                'help',
                'wordcount',
                'mentions',
                'codesample',
                'emoticons',
                'mediaembed',
                'casechange',
                'export',
                'formatpainter',
                'pageembed',
                'permanentpen',
                'footnotes',
                'advtemplate',
                'advtable',
                'advcode',
                'editimage',
                'tableofcontents',
                'mergetags',
                'powerpaste',
                'tinymcespellchecker',
                'autocorrect',
                'a11ychecker',
                'typography',
                'inlinecss',
              ],
              toolbar:
                'undo redo | blocks | ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help' +
                'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap',
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          /> */}
          {/* <button onClick={log}>Log editor content</button> */}

          {/* <Editor
            apiKey='g04euxdkjw2z95hsl13odx96rhcp6ft2q8czmpfoggquxxbi'
            init={{
              plugins:
                'ai tinycomments mentions anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount checklist mediaembed casechange export formatpainter pageembed permanentpen footnotes advtemplate advtable advcode editimage tableofcontents mergetags powerpaste tinymcespellchecker autocorrect a11ychecker typography inlinecss',
              toolbar:
                'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap | removeformat',
              tinycomments_mode: 'embedded',
              tinycomments_author: 'Author name',
              mergetags_list: [
                { value: 'First.Name', title: 'First Name' },
                { value: 'Email', title: 'Email' },
              ],
              ai_request: (request, respondWith) =>
                respondWith.string(() =>
                  Promise.reject('See docs to implement AI Assistant')
                ),
            }}
            initialValue='Welcome to TinyMCE!'
            value={description}
            onChange={setDescription}
          /> */}

          <ReactQuill
            theme='snow'
            modules={modules}
            formats={formats}
            value={description}
            onChange={setDescription}
          />

          {/* <Form.Control
            type='text'
            placeholder='Enter title'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></Form.Control> */}
        </Form.Group>

        <Form.Group controlId='image'>
          <Form.Label>Image</Form.Label>
          <Form.Control
            type='file'
            value={image_name}
            onChange={async (e) => {
              let imageName = e.target.value;
              setImageName(imageName);
              const base = await convertTo64(e.target.files[0]);
              setImage(base);
            }}
          />
        </Form.Group>

        <Button
          type='submit'
          style={{ backgroundColor: '#d49e33', border: 'None' }}
          className='mt-2'
          disabled={isLoading}
        >
          Create
        </Button>

        {isLoading && <Loader />}
      </Form>
    </FormContainer>
  );
};

export default CreatePostForm;
