import React, { useState } from 'react';
import { Button, Col, Table } from 'react-bootstrap';
import { Link, useLocation, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import {
  useGetCourseQuestionAnswerQuery,
  useRemoveAnswerMutation,
  useRemoveCourseQuestionMutation,
  useRemoveQuestionMutation,
} from '../../slices/coursesApiSlice';
import AddNewQuestionForm from './AddNewQuestionForm';
import ConfirmModal from '../../components/ConfirmModal';

const AllQuestionsByCourseScreen = () => {
  const [openForm, setOpenForm] = useState(false);
  const location = useLocation();
  const course = location?.state?.course;
  const courseSlug = location?.state?.courseSlug;
  const { id: uid } = useParams();

  const {
    data: getCourseQuestionAnswer,
    isCourseLoading,
    courseError,
    refetch,
  } = useGetCourseQuestionAnswerQuery({ uid });

  const [deleteAnswer] = useRemoveAnswerMutation();
  const [deleteCourseQuestion] = useRemoveCourseQuestionMutation();

  return (
    <main className='main-container'>
      <Link
        // to={`/admin/category-courses/${course?.category_class_id}/${courseSlug}`}
        to={`/admin/category-courses`}
      >
        <Button style={{ backgroundColor: '#d49e33', border: 'None' }}>
          {' '}
          Go back
        </Button>
      </Link>{' '}
      <Button
        style={{ backgroundColor: '#d49e33', border: 'None' }}
        onClick={() => {
          setOpenForm(!openForm);
        }}
      >
        Add new Question
      </Button>
      {openForm && <AddNewQuestionForm course_id={uid} />}
      <br />
      <br />
      {isCourseLoading ? (
        <Loader />
      ) : courseError ? (
        <Message variant='danger'>{courseError?.error}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Remove question</th>
              <th>Add Answer</th>
              <th>Answers</th>
              <th>Edit Answers</th>
              <th>Delete Answers</th>
              {/* <th>Edit</th>
              <th>Remove</th> */}
            </tr>
          </thead>
          <tbody>
            {getCourseQuestionAnswer?.data?.course_questions &&
              getCourseQuestionAnswer?.data?.course_questions.map(
                (question, index) => (
                  <tr key={question.id}>
                    <td>{index + 1}</td>
                    <td>{question.question}</td>
                    <td style={{ textAlign: 'center' }}>
                      <ConfirmModal
                        id={question?.id}
                        refetch={refetch}
                        deleteFunc={deleteCourseQuestion}
                      />
                    </td>
                    <td style={{ textAlign: 'center' }}>
                      <Link
                        to={`/admin/courses/questions/answer/${question.id}`}
                        state={{ question }}
                      >
                        <Button>Add Answer</Button>
                      </Link>
                    </td>
                    <td>
                      {question?.course_answers &&
                        question.course_answers.map((answer, i) => (
                          <Col key={answer.id}>
                            <span>
                              {i + 1} {answer?.answer}
                            </span>
                          </Col>
                        ))}
                    </td>

                    <td style={{ textAlign: 'center' }}>
                      {question?.course_answers &&
                        question.course_answers.map((answer, i) => (
                          <Col key={answer.id} className='my-2'>
                            <Link
                              to={`/admin/courses/questions/answer/edit/${answer.id}`}
                              state={{ course, courseId: uid }}
                            >
                              <Button>Edit Answer</Button>
                            </Link>
                          </Col>
                        ))}
                    </td>

                    <td style={{ textAlign: 'center' }}>
                      {question?.course_answers &&
                        question.course_answers.map((answer, i) => (
                          <Col key={answer.id} className='my-2'>
                            <ConfirmModal
                              id={answer?.id}
                              refetch={refetch}
                              deleteFunc={deleteAnswer}
                            />
                          </Col>
                        ))}
                    </td>
                  </tr>
                )
              )}
          </tbody>
        </Table>
      )}
    </main>
  );
};

export default AllQuestionsByCourseScreen;
