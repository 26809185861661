import React, { useState } from 'react';
import FormContainer from '../../components/FormContainer';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import { useCreateTestMutation } from '../../slices/freeApiSlice';
import { convertTo64 } from '../../helpers/convertTo64';

const CreatePdfForm = () => {
  const [name, setName] = useState('');
  let [image_name, setImageName] = useState('');
  const [image, setImage] = useState(null);

  // Create api call
  const [createTest, { isLoading }] = useCreateTestMutation();

  let imageName;
  const submitHandler = async (e) => {
    e.preventDefault();

    imageName = image_name.split('\\');
    imageName = imageName[imageName.length - 1].split('.');
    imageName = imageName[0];
    image_name = imageName;

    try {
      // Call API route
      const response = await createTest({
        name,
        image_name,
        image,
      }).unwrap();
      if (response?.status === 'success') {
        toast.success('Successfully Added a new Test');
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (error) {
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
    }
  };
  return (
    <FormContainer>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='name' className='my-3'>
          <Form.Label>Name</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='image'>
          <Form.Label>Image</Form.Label>
          <Form.Control
            type='file'
            value={image_name}
            onChange={async (e) => {
              let imageName = e.target.value;
              setImageName(imageName);
              const base = await convertTo64(e.target.files[0]);
              setImage(base);
            }}
          />
        </Form.Group>

        <Button
          type='submit'
          style={{ backgroundColor: '#d49e33', border: 'None' }}
          className='mt-2'
          disabled={isLoading}
        >
          Add
        </Button>

        {isLoading && <Loader />}
      </Form>
    </FormContainer>
  );
};

export default CreatePdfForm;
