import React from 'react';
import { useParams } from 'react-router-dom';
import {
  useAllReviewsQuery,
  useApproveReviewMutation,
} from '../../slices/coursesApiSlice';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { Button, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';

const ReviewScreen = () => {
  const { courseId: course_id } = useParams();

  const {
    data: allReviews,
    isLoading,
    error,
  } = useAllReviewsQuery({ course_id });

  const [approveReview] = useApproveReviewMutation();

  const approveHandler = async (e, uid, status) => {
    e.preventDefault();
    let statusCode;

    statusCode = status === 0 ? 1 : 0;

    console.log({ statusCode });

    try {
      // Call API route
      const response = await approveReview({
        uid,
        status: statusCode,
      }).unwrap();
      if (response?.status === 'success') {
        toast.success('Approwed');
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (error) {
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
    }
  };

  return (
    <main className='main-container'>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.error}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Comment</th>
              <th>Rating</th>
              <th>Approve</th>
            </tr>
          </thead>
          <tbody>
            {allReviews?.data?.data &&
              allReviews?.data?.data.map((review, index) => (
                <tr key={review.id}>
                  <td>{index + 1}</td>
                  <td>{review.comment}</td>
                  <td>{review.rating}</td>
                  <td style={{ textAlign: 'center' }}>
                    {' '}
                    <Button
                      onClick={(e) => {
                        approveHandler(e, review.id, review.status);
                      }}
                    >
                      {review.status === 0 ? 'Approve' : 'Decline'}
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </main>
  );
};

export default ReviewScreen;
