import React from 'react';
import { useTestsQuery } from '../../slices/freeApiSlice';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { Button, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const QuestionAnswerScreen = () => {
  // API Calls
  const { data: tests, isLoading, error } = useTestsQuery();

  return (
    <main className='main-container'>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.error}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Test Name</th>
              <th>Questions / Answer</th>
            </tr>
          </thead>
          <tbody>
            {tests?.data &&
              tests?.data.map((test, index) => (
                <tr key={test.id}>
                  <td>{index + 1}</td>
                  <td>{test.name}</td>
                  <td style={{ textAlign: 'center' }}>
                    <Link
                      to={`/admin/question-answer/${test.id}`}
                      state={{ test }}
                    >
                      <Button>Questions / Answers</Button>
                    </Link>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </main>
  );
};

export default QuestionAnswerScreen;
