import { apiSlice } from './apiSlice';

const adminInfo = localStorage.getItem('adminInfo')
  ? JSON.parse(localStorage.getItem('adminInfo'))
  : null;

export const freeApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    pdfs: builder.query({
      query: () => ({
        url: `/api/pdf`,
        method: 'POST',
      }),
      keepUnusedDataFor: 5,
    }),
    downloadPdf: builder.query({
      query: (name) => ({
        url: `/api/pdf/public-downoload/${name}`,
      }),
      keepUnusedDataFor: 5,
    }),
    createPdf: builder.mutation({
      query: (data) => ({
        url: `/api/pdf/create`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    removePdf: builder.mutation({
      query: (id) => ({
        url: `/api/pdf/delete/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    tests: builder.query({
      query: () => ({
        url: `/api/tests/all`,
        method: 'POST',
      }),
      keepUnusedDataFor: 5,
    }),
    createTest: builder.mutation({
      query: (data) => ({
        url: `/api/tests/create`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    singleTest: builder.query({
      query: (data) => ({
        url: `/api/tests/get-question-answers`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    udpateTest: builder.mutation({
      query: (data) => ({
        url: `/api/tests/update`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    createQuestion: builder.mutation({
      query: (data) => ({
        url: `/api/questions/create`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    questionAnswer: builder.query({
      query: (test_id) => ({
        url: `/api/tests/get-question-answers`,
        method: 'POST',
        body: test_id,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    removeQuestion: builder.mutation({
      query: (uid) => ({
        url: `/api/questions/delete/${uid}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    createProblem: builder.mutation({
      query: (data) => ({
        url: `/api/problem/create`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    problems: builder.query({
      query: (test_id) => ({
        url: `/api/tests/problems`,
        method: 'POST',
        body: test_id,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    problem: builder.query({
      query: (data) => ({
        url: `/api/problem/get`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    udpateProblem: builder.mutation({
      query: (data) => ({
        url: `/api/problem/update`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    removeProblem: builder.mutation({
      query: (uid) => ({
        url: `/api/problem/delete/${uid}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  usePdfsQuery,
  useDownloadPdfQuery,
  useCreatePdfMutation,
  useRemovePdfMutation,
  useTestsQuery,
  useUdpateTestMutation,
  useCreateTestMutation,
  useSingleTestQuery,
  useCreateQuestionMutation,
  useQuestionAnswerQuery,
  useRemoveQuestionMutation,
  useCreateProblemMutation,
  useProblemsQuery,
  useProblemQuery,
  useUdpateProblemMutation,
  useRemoveProblemMutation,
} = freeApiSlice;
