import React, { useState } from 'react';
import { Button, Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import ProblemForm from './ProblemForm';
import {
  useProblemsQuery,
  useRemoveProblemMutation,
} from '../../slices/freeApiSlice';
import ConfirmModal from '../../components/ConfirmModal';

const ProblemsScreen = () => {
  const [openForm, setOpenForm] = useState(false);

  const { testId: test_id } = useParams();

  const {
    data: problems,
    isLoading,
    error,
    refetch,
  } = useProblemsQuery({ uid: test_id });

  const [deleteProblem] = useRemoveProblemMutation();

  return (
    <main className='main-container'>
      <Link to='/admin/tests'>
        <Button style={{ backgroundColor: '#d49e33', border: 'None' }}>
          {' '}
          Go back
        </Button>
      </Link>{' '}
      <Button
        style={{ backgroundColor: '#d49e33', border: 'None' }}
        onClick={() => {
          setOpenForm(!openForm);
        }}
      >
        Add new Problem Result
      </Button>
      {openForm && <ProblemForm test_id={test_id} />}
      <br />
      <br />
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>EEE</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Edit</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {problems?.data?.problems &&
              problems?.data?.problems.map((problem, index) => (
                <tr key={problem.id}>
                  <td>{index + 1}</td>
                  <td>{problem.title}</td>
                  <td style={{ textAlign: 'center' }}>
                    <Link
                      to={`/admin/free/tests/${test_id}/problems/${problem.id}`}
                      state={{ problem }}
                    >
                      <Button>Edit Problem</Button>
                    </Link>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <ConfirmModal
                      id={problem?.id}
                      refetch={refetch}
                      deleteFunc={deleteProblem}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </main>
  );
};

export default ProblemsScreen;
