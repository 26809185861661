import React, { useState } from 'react';
import FormContainer from '../../components/FormContainer';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader';
import { useCreateCourseQuestionMutation } from '../../slices/coursesApiSlice';

const AddNewQuestionForm = ({ course_id }) => {
  const [question, setQuestion] = useState('');

  // Create api call
  const [createQuestion, { isLoading }] = useCreateCourseQuestionMutation();

  const submitHandler = async (e) => {
    e.preventDefault();

    try {
      // Call API route
      const response = await createQuestion({
        course_id,
        question,
      }).unwrap();
      if (response?.status === 'success') {
        toast.success('Successfully Added a new Question');
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (error) {
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
    }
  };
  return (
    <FormContainer>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='title' className='my-3'>
          <Form.Label>Question</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter question'
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Button
          type='submit'
          style={{ backgroundColor: '#d49e33', border: 'None' }}
          className='mt-2'
          disabled={isLoading}
        >
          Add
        </Button>

        {isLoading && <Loader />}
      </Form>
    </FormContainer>
  );
};

export default AddNewQuestionForm;
