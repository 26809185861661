import React, { useRef, useState } from 'react';
import FormContainer from '../../components/FormContainer';
import { Button, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Loader from '../../components/Loader';
import { useCreateCourseMutation } from '../../slices/coursesApiSlice';
import { convertTo64 } from '../../helpers/convertTo64';
import { changeSrc } from '../../helpers/changeSrc';
import { useUpoadImagebase64Mutation } from '../../slices/motivationApiSlice';
import { Editor } from '@tinymce/tinymce-react';

const CreateCourseForm = ({ slug }) => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [amount, setAmount] = useState(0);
  const [duration, setDuration] = useState('');
  const [module_title, setModuleTitle] = useState('');

  const { id: category_class_id } = useParams();
  let [module_content, setModuleContent] = useState('');

  let [image_name, setImageName] = useState('');
  const [image, setImage] = useState(null);

  const REACT_APP_TINY_EDITOR = process.env.REACT_APP_TINY_EDITOR;

  // Quill
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: [] }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image', 'video'],
      [{ color: [] }, { background: [] }],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'link',
    'color',
    'image',
    'background',
    'align',
    'size',
    'font',
    'video',
  ];

  const editorRef = useRef(null);
  const handleEditorChange = (newContent, editor) => {
    setModuleContent(newContent);
  };

  const handleProcedureContentChange = (content, delta, source, editor) => {
    setModuleContent(content);
    //let has_attribues = delta.ops[1].attributes || "";
    //console.log(has_attribues);
    //const cursorPosition = e.quill.getSelection().index;
    // this.quill.insertText(cursorPosition, "★");
    //this.quill.setSelection(cursorPosition + 1);
  };

  // Create api call
  const [createCourse, { isLoading }] = useCreateCourseMutation();
  const [uploadBase64] = useUpoadImagebase64Mutation();

  let imageName;
  const submitHandler = async (e) => {
    e.preventDefault();

    imageName = image_name.split('\\');
    imageName = imageName[imageName.length - 1].split('.');
    imageName = imageName[0];
    image_name = imageName;

    module_content = await changeSrc(module_content, uploadBase64);

    let modules = [];
    let moduleDict = { module_title, module_content };
    modules.push(moduleDict);

    try {
      // Call API route
      const response = await createCourse({
        name,
        description,
        amount,
        duration,
        category_class_id,
        modules,
        image_name,
        image,
      }).unwrap();
      if (response?.status === 'success') {
        toast.success('Successfully Added a new Course');
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (error) {
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
    }
  };
  return (
    <FormContainer>
      <Form onSubmit={submitHandler}>
        <Form.Group controlId='name' className='my-3'>
          <Form.Label>Title</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter Name'
            value={name}
            onChange={(e) => setName(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='description' className='my-3'>
          <Form.Label>Description</Form.Label>

          <ReactQuill
            theme='snow'
            modules={modules}
            formats={formats}
            value={description}
            onChange={setDescription}
          />
          {/* 
          <Form.Control
            as='textarea'
            rows={3}
            type='description'
            placeholder='Enter description'
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          ></Form.Control> */}
        </Form.Group>

        <Form.Group controlId='amount' className='my-3'>
          <Form.Label>Amount</Form.Label>
          <Form.Control
            type='number'
            placeholder='Enter amount'
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='duration' className='my-3'>
          <Form.Label>Duration</Form.Label>
          <Form.Control
            type='number'
            placeholder='Enter duration'
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='module_title' className='my-3'>
          <Form.Label>Module Title</Form.Label>
          <Form.Control
            type='text'
            placeholder='Enter title'
            value={module_title}
            onChange={(e) => setModuleTitle(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId='module_content' className='my-3'>
          <Form.Label>Module Description</Form.Label>
          {/* <Editor
            apiKey={REACT_APP_TINY_EDITOR}
            onInit={(evt, editor) => (editorRef.current = editor)}
            onEditorChange={handleEditorChange}
            init={{
              height: 500,
              menubar: false,
              plugins: [
                'advlist',
                'autolink',
                'lists',
                'link',
                'image',
                'charmap',
                'preview',
                'anchor',
                'searchreplace',
                'visualblocks',
                'code',
                'fullscreen',
                'insertdatetime',
                'media',
                'table',
                'code',
                'help',
                'wordcount',
                'mentions',
                'codesample',
                'emoticons',
                'mediaembed',
                'casechange',
                'export',
                'formatpainter',
                'pageembed',
                'permanentpen',
                'footnotes',
                'advtemplate',
                'advtable',
                'advcode',
                'editimage',
                'tableofcontents',
                'mergetags',
                'powerpaste',
                'tinymcespellchecker',
                'autocorrect',
                'a11ychecker',
                'typography',
                'inlinecss',
              ],
              toolbar:
                'undo redo | blocks | ' +
                'bold italic forecolor | alignleft aligncenter ' +
                'alignright alignjustify | bullist numlist outdent indent | ' +
                'removeformat | help' +
                'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table mergetags | align lineheight | tinycomments | checklist numlist bullist indent outdent | emoticons charmap',
              content_style:
                'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            }}
          /> */}
          <ReactQuill
            theme='snow'
            modules={modules}
            formats={formats}
            value={module_content}
            onChange={handleProcedureContentChange}
          />
        </Form.Group>

        <Form.Group controlId='image'>
          <Form.Label>Image</Form.Label>
          <Form.Control
            type='file'
            value={image_name}
            onChange={async (e) => {
              let imageName = e.target.value;
              setImageName(imageName);
              const base = await convertTo64(e.target.files[0]);
              setImage(base);
            }}
          />
        </Form.Group>

        <Button
          type='submit'
          style={{ backgroundColor: '#d49e33', border: 'None' }}
          className='mt-2'
          disabled={isLoading}
        >
          Add Course
        </Button>

        {isLoading && <Loader />}
      </Form>
    </FormContainer>
  );
};

export default CreateCourseForm;
