import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import FormContainer from '../../components/FormContainer';
import Loader from '../../components/Loader';
import {
  useEvolvesQuery,
  useUpdateEvolveMutation,
} from '../../slices/usersApiSlice';
import { toast } from 'react-toastify';

const EditEvolve = () => {
  const [name, setName] = useState('');

  let { uid } = useParams();

  const { data: evolves } = useEvolvesQuery();
  const [updateEvolve, { isLoading }] = useUpdateEvolveMutation();

  useEffect(() => {
    let evolve = evolves?.data.find((e) => e?.id === uid);
    setName(evolve?.name);
  }, [uid, evolves]);

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      // Call API route
      const response = await updateEvolve({
        uid,
        name,
      }).unwrap();

      if (response?.status === 'success') {
        toast.success('Successfully Updated Evolve');
      }
      setTimeout(() => {
        window.location.reload(false);
      }, 1000);
    } catch (error) {
      // Show Erorr
      const errorArr = error?.data?.errors;
      if (errorArr) {
        let arr = Object.values(errorArr);
        arr.map((e) => toast.error(e[0]));
      }
      if (error?.status === 500) {
        toast.error(error?.data?.data);
      }
      if (error?.originalStatus === 500) {
        toast.error(error?.error);
      }
    }
  };

  return (
    <main className='main-container'>
      <Link to={`/admin/evolve`}>
        <Button style={{ backgroundColor: '#d49e33', border: 'None' }}>
          {' '}
          Go back
        </Button>
      </Link>{' '}
      <FormContainer>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId='name' className='my-3'>
            <Form.Label>Title</Form.Label>
            <Form.Control
              type='text'
              placeholder='Enter Name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Button
            type='submit'
            style={{ backgroundColor: '#d49e33', border: 'None' }}
            className='mt-2'
            disabled={isLoading}
          >
            Update
          </Button>

          {isLoading && <Loader />}
        </Form>
      </FormContainer>
    </main>
  );
};

export default EditEvolve;
