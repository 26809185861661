import {
  BsGrid1X2Fill,
  BsFillGrid3X3GapFill,
  BsPeopleFill,
  BsFillHouseLockFill,
  BsLightningChargeFill,
  BsCollectionPlayFill,
  BsQuestionCircleFill,
  BsBank2,
  BsFan,
} from 'react-icons/bs';
import { Link } from 'react-router-dom';

export const Sidebar = ({ openSidebarToggle, OpenSidebar }) => {
  return (
    <aside
      id='sidebar'
      className={openSidebarToggle ? 'sidebar-responsive' : ''}
    >
      <div className='sidebar-title'>
        <div className='sidebar-brand'>
          <BsFillHouseLockFill className='icon_header' /> ADMIN
        </div>
        <span className='icon close_icon' onClick={OpenSidebar}>
          X
        </span>
      </div>

      <ul className='sidebar-list'>
        <Link to='/' style={{ textDecoration: 'None', color: 'grey' }}>
          <li className='sidebar-list-item'>
            <BsGrid1X2Fill className='icon' /> Dashboard
          </li>
        </Link>

        <Link
          to='/admin/motivation'
          style={{ textDecoration: 'None', color: 'grey' }}
        >
          <li className='sidebar-list-item'>
            <BsLightningChargeFill className='icon' /> MOTIVATION
          </li>
        </Link>
        <Link
          to='/admin/free'
          style={{ textDecoration: 'None', color: 'grey' }}
        >
          <li className='sidebar-list-item'>
            <BsFillGrid3X3GapFill className='icon' /> FREE
          </li>
        </Link>
        <Link
          to='/admin/category-courses'
          style={{ textDecoration: 'None', color: 'grey' }}
        >
          <li className='sidebar-list-item'>
            <BsCollectionPlayFill className='icon' /> COURSES
          </li>
        </Link>
        <Link
          to='/admin/users'
          style={{ textDecoration: 'None', color: 'grey' }}
        >
          <li className='sidebar-list-item'>
            <BsPeopleFill className='icon' /> Users
          </li>
        </Link>
        <Link
          to='/admin/question-answer'
          style={{ textDecoration: 'None', color: 'grey' }}
        >
          <li className='sidebar-list-item'>
            <BsQuestionCircleFill className='icon' /> Question/Answer
          </li>
        </Link>
        <Link
          to='/admin/transactions'
          style={{ textDecoration: 'None', color: 'grey' }}
        >
          <li className='sidebar-list-item'>
            <BsBank2 className='icon' /> Transactions
          </li>
        </Link>
        <Link
          to='/admin/evolve'
          style={{ textDecoration: 'None', color: 'grey' }}
        >
          <li className='sidebar-list-item'>
            <BsFan className='icon' /> Evolve
          </li>
        </Link>
        {/* <li className='sidebar-list-item'>
          <a href=''>
            <BsMenuButtonWideFill className='icon' /> Reports
          </a>
        </li>
        <li className='sidebar-list-item'>
          <a href=''>
            <BsFillGearFill className='icon' /> Setting
          </a>
        </li> */}
      </ul>
    </aside>
  );
};
