import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import './App.css';
import Header from './Header';
import { Sidebar } from './Sidebar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

function App() {
  const [openSidebarToggle, setOpenSidebarToggle] = useState(false);

  const OpenSidebar = () => {
    setOpenSidebarToggle(!openSidebarToggle);
  };

  // use userInfo state
  const { adminInfo } = useSelector((state) => state?.adminInfo);

  return (
    <div className='grid-container'>
      {adminInfo && (
        <>
          <Header OpenSidebar={OpenSidebar} />
          <Sidebar
            openSidebarToggle={openSidebarToggle}
            OpenSidebar={OpenSidebar}
          />
        </>
      )}

      <Outlet />
      <ToastContainer />
    </div>
  );
}

export default App;
