import { v4 as uuidv4 } from 'uuid';

export const changeSrc = async (description, uploadBase64) => {
  const appUrl = process.env.REACT_APP_URL;

  try {
    let srcRegex = /src=["'](.*?)["']/g;
    // Niz koji će sadržati sve pronađene src atribute
    var srcMatches = [];
    var match;

    // Pronađite sve src atribute koristeći regularni izraz i petlju
    while ((match = srcRegex.exec(description)) !== null) {
      srcMatches.push(match[1]);
    }

    for (const s of srcMatches) {
      const image_name64 = uuidv4();
      const response64 = await uploadBase64({
        image_name64,
        image64: s,
      }).unwrap();
      const imgSrc = `${appUrl}/api/show-file/${response64?.data}`;

      // Ažuriramo description sa novim src vrednostima
      description = description.replace(s, imgSrc);
    }
  } catch (e) {
    console.error('e ', e);
  }

  return description;
};

// WOrk with one image
// export const changeSrc = async (description, uploadBase64) => {
//     const appUrl = process.env.REACT_APP_URL;
//     const image_name64 = uuidv4();
//     // Prvo, konvertujte string u DOM element
//     const parser = new DOMParser();
//     const doc = parser.parseFromString(description, 'text/html');

//     // Api

//     // Zatim izvucite src atribut slike
//     const imageElement = doc.querySelector('img');
//     const image64 = imageElement.getAttribute('src');
//     try {
//       // console.log('src ', image);

//       const response64 = await uploadBase64({
//         image_name64,
//         image64,
//       }).unwrap();

//       const imgSrc = `${appUrl}/api/show-file/${response64?.data}`;

//       let srcRegex = /src=["'](.*?)["']/;

//       let srcMatch = description.match(srcRegex);

//       if (srcMatch) {
//         var izmenjenHtmlString = description.replace(
//           srcRegex,
//           'src="' + imgSrc + '"'
//         );
//         description = izmenjenHtmlString;
//       } else {
//         console.log('Nije pronađen src atribut.');
//       }
//     } catch (e) {
//       console.error('e ', e);
//     }

//     return description;
//   };
