import { apiSlice } from './apiSlice';

const adminInfo = localStorage.getItem('adminInfo')
  ? JSON.parse(localStorage.getItem('adminInfo'))
  : null;

export const motivationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    categories: builder.query({
      query: () => ({
        url: `/api/categories`,
        method: 'POST',
      }),
      keepUnusedDataFor: 5,
    }),
    categoryBlogs: builder.query({
      query: (uid) => ({
        url: `/api/categories/blogs`,
        method: 'POST',
        body: uid,
      }),
      keepUnusedDataFor: 5,
    }),
    createBlog: builder.mutation({
      query: (data) => ({
        url: `/api/blog/create`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    udpateBlog: builder.mutation({
      query: (data) => ({
        url: `/api/blog/update`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    removeBlog: builder.mutation({
      query: (id) => ({
        url: `/api/blog/delete/${id}`,
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
    singleBlog: builder.query({
      query: (slug) => ({
        url: `/api/blog/get`,
        method: 'POST',
        body: slug,
      }),
      keepUnusedDataFor: 5,
    }),
    upoadImagebase64: builder.mutation({
      query: (data) => ({
        url: `/api/upload-file`,
        method: 'POST',
        body: data,
        headers: {
          Authorization: `Bearer ${adminInfo?.data}`,
        },
      }),
      keepUnusedDataFor: 5,
    }),
  }),
});

export const {
  useCategoriesQuery,
  useCategoryBlogsQuery,
  useCreateBlogMutation,
  useUdpateBlogMutation,
  useRemoveBlogMutation,
  useSingleBlogQuery,
  useUpoadImagebase64Mutation,
} = motivationApiSlice;
