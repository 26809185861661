import React from 'react';
import { useAllTransactionsQuery } from '../../slices/usersApiSlice';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import { Table } from 'react-bootstrap';

const TransactionScreen = () => {
  // Call API
  const { data: allTransactions, isLoading, error } = useAllTransactionsQuery();

  return (
    <main className='main-container'>
      <div className='main-title'>
        <h3>Transactions</h3>
      </div>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.error}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>User</th>
              <th>Course Name</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {allTransactions?.data &&
              allTransactions?.data.map((transaction, index) => (
                <tr key={transaction.id}>
                  <td>{index + 1}</td>
                  <td>
                    {transaction.user.first_name} {transaction.user.last_name}
                  </td>
                  <td>{transaction.course.name}</td>
                  <td>
                    {transaction.amount} {transaction.currency}
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </main>
  );
};

export default TransactionScreen;
