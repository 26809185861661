import React, { useState } from 'react';
import {
  useCategoryBlogsQuery,
  useRemoveBlogMutation,
} from '../../slices/motivationApiSlice';
import { Button, Table } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../components/Loader';
import Message from '../../components/Message';
import CreatePostForm from './CreatePostForm';
import ConfirmModal from '../../components/ConfirmModal';

const AllPostsScreen = () => {
  const [openForm, setOpenForm] = useState(false);
  const { id: uid, slug } = useParams();

  // Call API
  const {
    data: categoriyBlogs,
    isLoading,
    error,
    refetch,
  } = useCategoryBlogsQuery({ slug });

  const [deletePost] = useRemoveBlogMutation();

  return (
    <main className='main-container'>
      <Link to='/admin/motivation'>
        <Button style={{ backgroundColor: '#d49e33', border: 'None' }}>
          {' '}
          Go back
        </Button>
      </Link>{' '}
      <Button
        style={{ backgroundColor: '#d49e33', border: 'None' }}
        onClick={() => {
          setOpenForm(!openForm);
        }}
      >
        Add new Blog
      </Button>
      {openForm && <CreatePostForm category_id={uid} />}
      <br />
      <br />
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant='danger'>{error?.error || error?.data?.data}</Message>
      ) : (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th>Title</th>
              <th>Edit</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            {categoriyBlogs?.data?.blogs &&
              categoriyBlogs?.data?.blogs.map((blog, index) => (
                <tr key={blog.id}>
                  <td>{index + 1}</td>
                  <td>{blog.name}</td>
                  <td style={{ textAlign: 'center' }}>
                    <Link
                      to={`/admin/motivation/posts/${blog.id}/${blog.slug}`}
                      state={{ categoySlug: slug }}
                    >
                      <Button>Edit Blog</Button>
                    </Link>
                  </td>
                  <td style={{ textAlign: 'center' }}>
                    <ConfirmModal
                      id={blog?.id}
                      refetch={refetch}
                      deleteFunc={deletePost}
                    />
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </main>
  );
};

export default AllPostsScreen;
