import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from 'react-router-dom';
import './bootstrap.custom.css';
import { Provider } from 'react-redux';
import App from './App.jsx';
import PrivateRoute from './components/PrivateRoute';
import Home from './Home.jsx';
import store from './store.js';
import MotivationScreen from './screens/motivations/MotivationScreen.jsx';
import AllPostsScreen from './screens/motivations/AllPostsScreen';
import LoginScreen from './screens/LoginScreen';
import EditPostScreen from './screens/motivations/EditPostScreen';
import FreeScreen from './screens/free/FreeScreen';
import PdfScreen from './screens/free/PdfScreen';
import TestScreen from './screens/free/TestScreen';
import QuestionsScreen from './screens/free/QuestionsScreen';
import CategoryCoursesScreen from './screens/courses/CategoryCoursesScreen';
import AllCoursesByCategoryScreen from './screens/courses/AllCoursesByCategoryScreen';
import CreateCourseModulesScreen from './screens/courses/CreateCourseModulesScreen';
import EditModuleScreen from './screens/courses/EditModuleScreen';
import EditCourseScreen from './screens/courses/EditCourseScreen';
import UsersScreen from './screens/users/UsersScreen';
import QuestionAnswerScreen from './screens/question-answer/QuestionAnswerScreen';
import SingleTestQuestionAnswerScreen from './screens/question-answer/SingleTestQuestionAnswerScreen';
import TransactionScreen from './screens/transactions/TransactionScreen';
import AllQuestionsByCourseScreen from './screens/courses/AllQuestionsByCourseScreen';
import AddQuestionAnswerScreen from './screens/courses/AddQuestionAnswerScreen';
import EditTestScreen from './screens/free/EditTestScreen.jsx';
import ProblemsScreen from './screens/free/ProblemsScreen.jsx';
import EditProblemScreen from './screens/free/EditProblemScreen.jsx';
import EditAnswerScreen from './screens/courses/EditAnswerScreen.jsx';
import Evolve from './screens/evolve/Evolve.jsx';
import EditEvolve from './screens/evolve/EditEvolve.jsx';
import ReviewScreen from './screens/courses/ReviewScreen.jsx';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path='/' element={<App />}>
      <Route path='/admin/login' element={<LoginScreen />} />
      <Route path='' element={<PrivateRoute />}>
        <Route path='/' element={<Home />} />
        <Route path='/admin/motivation' element={<MotivationScreen />} />
        <Route
          path='/admin/motivation/category/:id/:slug'
          element={<AllPostsScreen />}
        />
        <Route
          path='/admin/motivation/posts/:id/:slug'
          element={<EditPostScreen />}
        />
        <Route path='/admin/free' element={<FreeScreen />} />
        <Route path='/admin/pdfs' element={<PdfScreen />} />
        <Route path='/admin/tests' element={<TestScreen />} />
        <Route path='/admin/tests/:testId' element={<EditTestScreen />} />
        <Route
          path='/admin/free/tests/problems/:testId'
          element={<ProblemsScreen />}
        />
        <Route
          path='/admin/free/tests/:testId/problems/:problemId'
          element={<EditProblemScreen />}
        />

        <Route
          path='/admin/free/tests/questions/:id'
          element={<QuestionsScreen />}
        />
        <Route
          path='/admin/category-courses'
          element={<CategoryCoursesScreen />}
        />
        <Route
          path='/admin/category-courses/:id/:slug'
          element={<AllCoursesByCategoryScreen />}
        />
        <Route
          path='/admin/category-courses/questions/:id'
          element={<AllQuestionsByCourseScreen />}
        />
        <Route
          path='/admin/courses/questions/answer/:id'
          element={<AddQuestionAnswerScreen />}
        />

        <Route
          path='/admin/category-courses/modules/:id'
          element={<CreateCourseModulesScreen />}
        />

        <Route
          path='/admin/courses/modules/:id'
          element={<EditModuleScreen />}
        />
        <Route path='/admin/courses/:id' element={<EditCourseScreen />} />
        <Route path='/admin/users' element={<UsersScreen />} />
        <Route
          path='/admin/question-answer'
          element={<QuestionAnswerScreen />}
        />
        <Route
          path='/admin/question-answer/:id'
          element={<SingleTestQuestionAnswerScreen />}
        />
        <Route
          path='/admin/courses/questions/answer/edit/:id'
          element={<EditAnswerScreen />}
        />
        <Route path='/admin/transactions' element={<TransactionScreen />} />
        <Route path='/admin/evolve' element={<Evolve />} />
        <Route path='/admin/evolve/:uid' element={<EditEvolve />} />
        <Route
          path='/admin/courses/review/:courseId'
          element={<ReviewScreen />}
        />
      </Route>
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);
